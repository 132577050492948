import { ComponentPublicInstance, VNode, h } from "vue";
import { BvMsgBoxData, BvMsgBoxOptions } from "bootstrap-vue";
import { captureException } from "@/utils/sentry";

export type ErrorType =
  | {
      // 他にもkeyがあるが、使用しないため省略
      response?: {
        data: { [key: string]: string | string[] } | Blob;
        status: number;
      };
    }
  | Error
  | undefined;

const showModal = (
  self: ComponentPublicInstance,
  message: string | Array<VNode>,
  options?: BvMsgBoxOptions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<BvMsgBoxData> => {
  // @ts-ignore
  return self.$bvModal.msgBoxOk(message, {
    centered: true,
    hideHeader: true,
    okOnly: true,
    ...options
  });
};

/**
 * サーバー通信でエラーをキャッチした時用のエラーモーダル。
 * エラー情報が入っている場合はエラー情報のモーダルを表示。
 * ネットワークエラーなど定義した型以外でエラーレスポンスが返ってきた場合は、定型文エラーのモーダルを表示。
 * 基本は下記のように使用。
 * 【通常パターン】
 * asyncService.asyncMethod().then(() => {
 *  anyFunction();
 * }).catch(error => {
 *  showErrorModal(this, error);
 * });
 * 【Promise.allパターン】
 * await Promise.all([promiseObj1, promiseObj2]).catch(error => {
 *  showErrorModal(this, error);
 * });
 * 【try catchパターン】
 * try {
 *  await asyncService.asyncMethod().then(() => {
 *    anyFunction();
 *  })
 * } catch(error => {
 *  showErrorModal(this, error);
 * });
 * @param self
 * @param error
 * @returns {Promise} モーダルが閉じられた場合に解決される
 */
export const showErrorModal = async (
  self: ComponentPublicInstance,
  error: ErrorType,
  options?: BvMsgBoxOptions,
  textClass?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  console.log(error);
  if (!error) {
    // handle undefined
    captureException(
      new Error("unknown error occurred" + JSON.stringify(error))
    );
    return showModal(self, "エラーが発生しました。", options);
  } else if ("cause" in error) {
    // handle Error
    captureException(error);
    return showModal(self, "エラーが発生しました。", options);
  } else if (!("response" in error) || !error.response?.data) {
    // handle response.data not existing
    captureException(error as Error);
    return showModal(self, "エラーが発生しました。", options);
  }

  let errorData: Record<string, string | string[]> = {};
  if (error.response.data instanceof Blob) {
    try {
      const text = await error.response.data.text();
      errorData = JSON.parse(text);
    } catch (e) {
      return showModal(self, "エラーが発生しました。", options);
    }
  } else {
    errorData = error.response.data;
  }

  const textNodes: VNode[] = [];
  for (const key in errorData) {
    const errorObject = errorData[key];
    const messages = Array.isArray(errorObject) ? errorObject : [errorObject];
    messages.forEach((message: string) => {
      textNodes.push(h("p", { class: textClass }, message));
    });
  }
  return showModal(self, textNodes, options);
};
