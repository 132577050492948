import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { checkAuth } from "@/router/guards/checkAuth";
import { checkPasswordForceChange } from "@/router/guards/checkPasswordForceChange";
import { munchkinVisitWebPage } from "@/router/afterHooks/munchkinVisitWebPage";
import { officeIdHandler } from "@/router/guards/officeIdHandler";

import multiguard from "vue-router-multiguard";
import { checkPermission } from "./guards/checkPermission";
import { autoUpdateHook } from "@/router/afterHooks/appUpdatorHook";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth/",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/layouts/BeforeLogin.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: "password-reset",
        name: "PasswordReset",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/auth/PasswordReset.vue"
          ),
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: "password/reset/confirm/:uid/:token/",
        name: "PasswordResetConfirm",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/auth/PasswordResetConfirm.vue"
          ),
        meta: {
          allowAnonymous: true
        }
      }
    ]
  },
  //// メンテナンス
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "@/views/maintenance/index.vue"
      ),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/layouts/Corporation.vue"),
    children: [
      {
        path: "/",
        name: "Root",
        redirect: "/tasks",
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/password-force-change",
        name: "PasswordForceChange",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/auth/PasswordForceChange.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/corporation-info-change",
        name: "corporationInfoChange",
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/views/auth/CorporationInfoChange.vue"
          ),
        meta: {
          allowCorporationRoot: true
        }
      },
      //// タスク一覧
      {
        path: "/tasks",
        name: "Tasks",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/tasks/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      },

      //// 案件関連
      {
        path: "/works",
        name: "Works",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/works/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/works/new",
        name: "WorksNew",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/works/New.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/works/:id/edit",
        name: "WorksEdit",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/works/Edit.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      //// シフト管理
      {
        path: "/shifts",
        name: "Shifts",
        component: () =>
          import(/* webpackChunkName: "shift" */ "@/views/shifts/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      //// 評価関連
      {
        path: "/evaluation-by-worker-summary",
        name: "EvaluationByWorkerSummary",
        component: () =>
          import(
            /* webpackChunkName: "evaluations_by_worker" */ "@/views/evaluationByWorkerSummary/Index.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/evaluation-by-office-summary",
        name: "EvaluationByOfficeSummary",
        component: () =>
          import(
            /* webpackChunkName: "evaluations_by_worker" */ "@/views/evaluationByOfficeSummary/Index.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      //// 応募履歴一覧
      {
        path: "/applications/histories",
        name: "ApplicationHistories",
        component: () =>
          import(
            /* webpackChunkName: "applications" */ "@/views/applications/histories/Index.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      //// 労働条件通知書
      {
        path: "/applications/:id/notice-of-employment",
        name: "NoticeOfEmployment",
        component: () =>
          import(
            /* webpackChunkName: "applications" */ "@/views/applications/NoticeOfEmployment.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/applications/:id/notice-of-employment/archive/:archiveId",
        name: "NoticeOfEmploymentArchive",
        component: () =>
          import(
            /* webpackChunkName: "applications" */ "@/views/applications/NoticeOfEmployment.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      //// ワーカー関連
      {
        path: "/workers",
        name: "Workers",
        component: () =>
          import(/* webpackChunkName: "worker" */ "@/views/workers/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/workers/:workerId",
        name: "WorkersDetail",
        component: () =>
          import(/* webpackChunkName: "worker" */ "@/views/workers/Detail.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/templates",
        name: "Templates",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/templates/Index.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/templates/:id/edit",
        name: "TemplatesEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/templates/Edit.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/templates/new",
        name: "TemplatesNew",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/views/templates/New.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },

      //// 事業所関連
      {
        path: "/offices",
        name: "Offices",
        component: () =>
          import(/* webpackChunkName: "office" */ "@/views/offices/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/offices/:id/edit",
        name: "OfficesEdit",
        props: true,
        component: () =>
          import(/* webpackChunkName: "office" */ "@/views/offices/Edit.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/offices/new",
        name: "OfficesNew",
        component: () =>
          import(/* webpackChunkName: "office" */ "@/views/offices/New.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/offices/:officeId/review",
        name: "OfficesReview",
        component: () =>
          import(/* webpackChunkName: "office" */ "@/views/offices/Review.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/offices/:officeId/anonymous-evaluations",
        name: "AnonymousEvaluations",
        component: () =>
          import(
            /* webpackChunkName: "anonymous_evaluations" */ "@/views/anonymousEvaluations/index.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/offices/:officeId/code",
        name: "OfficesCode",
        component: () =>
          import(/* webpackChunkName: "office" */ "@/views/offices/Code.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      //// 研修関連
      {
        path: "/trainings",
        name: "Trainings",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/trainings/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/trainings/new",
        name: "TrainingsNew",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/trainings/New.vue"),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/trainings/:id/edit",
        name: "TrainingsEdit",
        component: () =>
          import(/* webpackChunkName: "work" */ "@/views/trainings/Edit.vue"),
        meta: {
          allowCorporationUser: true
        }
      },

      //// ユーザー関連
      {
        path: "/corporations-users",
        name: "CorporationsUsers",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/views/corporationsUsers/Index.vue"
          ),
        meta: {
          allowCorporationRoot: true,
          allowCorporationAreaManager: true
        }
      },
      {
        path: "/corporations-users/new",
        name: "CorporationsUserNew",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/views/corporationsUsers/New.vue"
          ),
        meta: {
          allowCorporationRoot: true,
          allowCorporationAreaManager: true
        }
      },
      {
        path: "/corporations-users/:id/edit",
        name: "CorporationsUserEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/views/corporationsUsers/Edit.vue"
          ),
        meta: {
          allowCorporationRoot: true,
          allowCorporationAreaManager: true
        }
      },

      //// エリア関連
      {
        path: "/corporations-areas",
        name: "CorporationsAreas",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/views/corporationsAreas/Index.vue"
          ),
        meta: {
          allowCorporationRoot: true,
          allowCorporationAreaManager: true
        }
      },
      {
        path: "/corporations-areas/new",
        name: "CorporationsAreaNew",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/views/corporationsAreas/New.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },
      {
        path: "/corporations-areas/:id/edit",
        name: "CorporationsAreaEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/views/corporationsAreas/Edit.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },

      //// メッセージ関連
      {
        path: "/messages",
        name: "Messages",
        component: () =>
          import(/* webpackChunkName: "message" */ "@/views/Messages.vue"),
        meta: {
          allowCorporationUser: true
        }
      },

      //// 利用明細関連
      {
        path: "/reports",
        name: "Reports",
        component: () =>
          import(/* webpackChunkName: "report" */ "@/views/Reports.vue"),
        meta: {
          allowCorporationUser: true
        }
      },

      //// 請求書関連
      {
        path: "/billings",
        name: "Billings",
        component: () =>
          import(/* webpackChunkName: "billings" */ "@/views/Billings.vue"),
        meta: {
          allowCorporationUser: true
        }
      },

      //// 賃金台帳関連
      {
        path: "/payroll-registers",
        name: "PayrollRegisters",
        component: () =>
          import(
            /* webpackChunkName: "payroll_registers" */ "@/views/PayrollRegisters.vue"
          ),
        meta: {
          allowCorporationRoot: true
        }
      },

      //// 給与支払報告書
      {
        path: "/payroll-reports",
        name: "PayrollReports",
        component: () =>
          import(
            /* webpackChunkName: "payroll_reports" */ "@/views/PayrollReports.vue"
          ),
        meta: {
          allowCorporationRoot: true
        }
      },

      //// ワーカー招待ページ
      {
        path: "/invitation-link",
        name: "invitationLink",
        component: () =>
          import(
            /* webpackChunkName: "payroll_reports" */ "@/views/InvitationLink.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },

      //// 法人ブラックリスト一括登録
      {
        path: "/import-corporation-blacklist",
        name: "ImportCorporationBlacklist",
        component: () =>
          import(
            /* webpackChunkName: "payroll_reports" */ "@/views/ImportCorporationBlacklist.vue"
          ),
        meta: {
          allowCorporationUser: true
        }
      },

      //// Recruiting
      {
        path: "recruitings",
        name: "Recruitings",
        component: () =>
          import(/* webpackChunkName: "ats" */ "@/views/recruitings/Index.vue"),
        meta: {
          allowCorporationUser: true
        }
      }
    ]
  },
  {
    path: "/console/auth/",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/console/layouts/BeforeLogin.vue"
      ),
    children: [
      {
        path: "login",
        name: "ConsoleLogin",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/console/views/auth/Login.vue"
          ),
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: "password-reset",
        name: "ConsolePasswordReset",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/console/views/auth/PasswordReset.vue"
          ),
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: "password/reset/confirm/:uid/:token/",
        name: "ConsolePasswordResetConfirm",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/console/views/auth/PasswordResetConfirm.vue"
          ),
        meta: {
          allowAnonymous: true
        }
      }
    ]
  },

  {
    path: "/console/",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/console/layouts/Console.vue"),
    children: [
      {
        path: "",
        name: "ConsoleRoot",
        redirect: {
          name: "ConsoleVerifyQueues"
        },
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: "password-force-change",
        name: "ConsolePasswordForceChange",
        meta: {
          allowAnonymous: true
        },
        component: () =>
          import(
            /* webpackChunkName: "main" */ "@/console/views/auth/PasswordForceChange.vue"
          )
      },

      //// 案件関連
      {
        path: "works",
        name: "ConsoleWorks",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/console/views/works/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "works/:id",
        name: "ConsoleWorksDetail",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/console/views/works/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "applications/:id",
        name: "ConsoleApplicationsDetail",
        component: () =>
          import(
            /* webpackChunkName: "work" */ "@/console/views/applications/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      //// ワーカー関連
      {
        path: "workers",
        name: "ConsoleWorkers",
        component: () =>
          import(
            /* webpackChunkName: "worker" */ "@/console/views/workers/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "workers/:workerId",
        name: "ConsoleWorkersDetail",
        component: () =>
          import(
            /* webpackChunkName: "worker" */ "@/console/views/workers/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "templates",
        name: "ConsoleTemplates",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/templates/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "templates/:id",
        name: "ConsoleTemplatesDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/templates/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "templates/bulk-create",
        name: "ConsoleTemplatesBulkCreate",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/templates/bulk-create/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "abtests",
        name: "ConsoleAbTests",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/abtests/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "abtests/:id",
        name: "ConsoleAbTestDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/abtests/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "ads",
        name: "ConsoleAds",
        component: () =>
          import(/* webpackChunkName: "ad" */ "@/console/views/ads/Index.vue"),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "ads/new",
        name: "ConsoleAdsNew",
        props: true,
        component: () =>
          import(/* webpackChunkName: "ad" */ "@/console/views/ads/New.vue"),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "ads/:id/edit",
        name: "ConsoleAdsEdit",
        props: true,
        component: () =>
          import(/* webpackChunkName: "ad" */ "@/console/views/ads/Edit.vue"),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "verifyqueues",
        name: "ConsoleVerifyQueues",
        component: () =>
          import(
            /* webpackChunkName: "verifyqueue" */ "@/console/views/verifyqueues/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "verifyqueues/:id",
        name: "ConsoleVerifyQueuesDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/verifyqueues/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "qualifications",
        name: "ConsoleQualifications",
        component: () =>
          import(
            /* webpackChunkName: "qualification" */ "@/console/views/qualifications/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "qualifications/:id",
        name: "ConsoleQialificationsDetail",
        component: () =>
          import(
            /* webpackChunkName: "qualification" */ "@/console/views/qualifications/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "cancelrequests",
        name: "ConsoleCancelRequests",
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/cancelrequests/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "cancelrequests/:id",
        name: "ConsoleCancelRequestsDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "template" */ "@/console/views/cancelrequests/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "giftrequests",
        name: "ConsoleGiftRequests",
        component: () =>
          import(
            /* webpackChunkName: "giftrequests" */ "@/console/views/giftrequests/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "transferrequests/withdrawal",
        name: "ConsoleTransferRequestsWithdrawal",
        component: () =>
          import(
            /* webpackChunkName: "transferrequests/withdrawal" */ "@/console/views/transferrequests/withdrawal/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "transferrequests/salary",
        name: "ConsoleTransferRequestsSalary",
        component: () =>
          import(
            /* webpackChunkName: "transferrequests/salary" */ "@/console/views/transferrequests/salary/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "billing/payment",
        name: "BillingPayment",
        component: () =>
          import(
            /* webpackChunkName: "billing/payment" */ "@/console/views/billing/payment/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "attendancecorrections",
        name: "ConsoleAttendanceCorrections",
        component: () =>
          import(
            /* webpackChunkName: "attendancecorrections" */ "@/console/views/attendancecorrections/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "attendancecorrections/:id",
        name: "ConsoleAttendanceCorrectionsDetail",
        component: () =>
          import(
            /* webpackChunkName: "attendancecorrections" */ "@/console/views/attendancecorrections/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      //// 事業所関連
      {
        path: "offices",
        name: "ConsoleOffices",
        component: () =>
          import(
            /* webpackChunkName: "office" */ "@/console/views/offices/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "offices/bulk-create",
        name: "ConsoleOfficesBulkCreate",
        component: () =>
          import(
            /* webpackChunkName: "office" */ "@/console/views/offices/bulk-create/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "offices/:id",
        name: "ConsoleOfficesDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "office" */ "@/console/views/offices/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "offices/:officeId/review",
        name: "ConsoleOfficesReview",
        component: () =>
          import(
            /* webpackChunkName: "office" */ "@/console/views/offices/Review.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      //// 法人関連
      {
        path: "corporations/accounts/requests",
        name: "ConsoleCorporationsAccountRequest",
        component: () =>
          import(
            /* webpackChunkName: "corporation" */ "@/console/views/corporations/accountrequests/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "corporations/accounts/requests/new",
        name: "ConsoleCorporationsAccountRequestNew",
        component: () =>
          import(
            /* webpackChunkName: "corporation" */ "@/console/views/corporations/accountrequests/New.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "corporations/accounts/requests/:id",
        name: "ConsoleCorporationsAccountRequestDetail",
        component: () =>
          import(
            /* webpackChunkName: "corporation" */ "@/console/views/corporations/accountrequests/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "corporations/accounts/requests/:id/edit",
        name: "ConsoleCorporationsAccountRequestEdit",
        component: () =>
          import(
            /* webpackChunkName: "corporation" */ "@/console/views/corporations/accountrequests/Edit.vue"
          ),
        meta: {
          allowStaff: true
        }
      },

      //// admin処理履歴関連
      {
        path: "adminreports",
        name: "ConsoleAdminReports",
        component: () =>
          import(
            /* webpackChunkName: "adminreport" */ "@/console/views/AdminReports.vue"
          ),
        meta: {
          allowStaff: true
        }
      },

      //// 請求書関連
      {
        path: "billings",
        name: "ConsoleBillings",
        component: () =>
          import(/* webpackChunkName: "billings" */ "@/views/Billings.vue"), // 法人のものを参照
        meta: {
          allowStaff: true
        }
      },

      //// 未出退勤関連
      {
        path: "insufficientattendances",
        name: "ConsoleInsufficientAttendances",
        component: () =>
          import(
            /* webpackChunkName: "insufficientattendances" */ "@/console/views/insufficientattendances/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "insufficientattendances/:id",
        name: "ConsoleInsufficientAttendanceDetail",
        component: () =>
          import(
            /* webpackChunkName: "insufficientattendanceDetail" */ "@/console/views/insufficientattendances/Detail.vue"
          ),
        meta: {
          allowStaff: true
        }
      },

      //// ワーカー向けお知らせ関連
      {
        path: "informations",
        name: "Informations",
        component: () =>
          import(
            /* webpackChunkName: "informations" */ "@/console/views/informations/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "informations/:id/edit",
        name: "InformationsEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "informations" */ "@/console/views/informations/Edit.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "informations/new",
        name: "InformationsNew",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "informations" */ "@/console/views/informations/New.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "points",
        name: "Points",
        component: () =>
          import(
            /* webpackChunkName: "points" */ "@/console/views/points/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      //// 法人向けお知らせ
      {
        path: "global-corporation-announcements",
        name: "GlobalCorporationAnnouncements",
        component: () =>
          import(
            /* webpackChunkName: "global_corporation_announcements" */ "@/console/views/global_corporation_announcements/Index.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "global-corporation-announcements/new",
        name: "GlobalCorporationAnnouncementsNew",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "global_corporation_announcement" */ "@/console/views/global_corporation_announcements/New.vue"
          ),
        meta: {
          allowStaff: true
        }
      },
      {
        path: "global-corporation-announcements/:id",
        name: "GlobalCorporationAnnouncementsEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "global_corporation_announcement_edit" */ "@/console/views/global_corporation_announcements/Edit.vue"
          ),
        meta: {
          allowStaff: true
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(
  multiguard([
    checkAuth,
    checkPasswordForceChange,
    checkPermission,
    officeIdHandler
  ])
);
router.afterEach(munchkinVisitWebPage);
router.afterEach(autoUpdateHook);

export default router;
